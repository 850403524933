import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from 'docComponents';
import { Text, Input } from 'hudl-uniform-ui-components';

const snippet = require('raw-loader!../../../../../data/snippets/input.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{ React: React, Input: Input }}
        code={snippet}
        platform="react"
        gitHubLink="forms/input"
      />

      <Section title="Props">
        <PropList>
          <PropListItem name="type" types={['string']}>
            <Text>The type of input you want: text, password, url, etc.</Text>
            <List type="unordered">
              <li>
                <code>text</code>
              </li>
              <li>
                <code>number</code>
              </li>
              <li>
                <code>date</code>
              </li>
              <li>
                <code>time</code>
              </li>
              <li>
                <code>email</code>
              </li>
              <li>
                <code>password</code>
              </li>
              <li>
                <code>url</code>
              </li>
              <li>
                <code>tel</code>
              </li>
              <li>
                <code>range</code>
              </li>
              <li>
                <code>file</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="label" types={['string']}>
            <Text>The text that appears above the input.</Text>
          </PropListItem>

          <PropListItem name="name" types={['string']}>
            <Text>
              The name for the input. Passed to the <code>id</code> of the input
              and the <code>for</code> attribute of the label to bind them
              together.
            </Text>
          </PropListItem>

          <PropListItem name="required" types={['bool']}>
            <Text>
              Determines whether the required text appears next to the label.
            </Text>
          </PropListItem>

          <PropListItem name="disabled" types={['bool']}>
            <Text>Determines whether the input is disabled.</Text>
          </PropListItem>

          <PropListItem name="hasError" types={['bool']}>
            <Text>
              Determine whether to show an error state (red text and border) on
              the input.
            </Text>
          </PropListItem>

          <PropListItem name="helpText" types={['string', 'element']}>
            <Text>
              The text that appears below the input, usually to guide the user
              on what to provide.
            </Text>
          </PropListItem>

          <PropListItem name="hideLabel" types={['bool']}>
            <Text>Determines whether to hide the label.</Text>
          </PropListItem>

          <PropListItem name="hasDivider" types={['bool']}>
            <Text>
              Determines whether to show a visual divider above the input.
            </Text>
          </PropListItem>

          <PropListItem name="className" types={['string']}>
            <Text>Add a custom class to the form element.</Text>
          </PropListItem>

          <PropListItem name="inputRef" types={['func']}>
            <Text>A ref passed to the native input element.</Text>
          </PropListItem>

          <PropListItem name="qaId" types={['string']}>
            <Text>
              A unique-enough string that appears as a data attribute in the
              rendered code and serves as a hook for automated tests.
            </Text>
          </PropListItem>

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input">a regular HTML input</Link>.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            If you pass in a <code>value</code> prop, be sure you also pass in
            an <code>onChange</code> prop so the{' '}
            <Link href="https://reactjs.org/docs/forms.html#controlled-components">
              component will update
            </Link>{' '}
            when the user types.
          </li>
          <li>
            Native input props can be passed in such as <code>onChange</code>,{' '}
            <code>default</code>, <code>max</code>, etc.
          </li>
          <li>
            Since the <code>name</code> attribute is passed to the{' '}
            <code>input</code>
            ’s <code>id</code>, make sure you provide a unique name.
          </li>
          <li>
            To show an error message, simply pass in <code>hasError</code> and
            put the error text in the <code>helpText</code> attribute.
          </li>
        </List>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
